(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function($) {
	"use strict";

	var 
		// preloader = require("./modules/infinite_preloader.js"),
		map = require("./modules/gmap.js"),
		pageFader = require("./modules/page_fader.js"),
		screenEvents = require("./modules/screens.js"),
		productsGallery = require("./modules/products-gallery.js"),
		tabs = require("./modules/tabs.js"),
		bbslider = require("./modules/bbslider.js"),
		bbgallery = require("./modules/bbgallery.js"),
		videoLoader = require("./modules/video_loader.js"),
		simpleSliders = require("./modules/simpleSlider.js"),
		autoSliders = require("./modules/autoSlider.js"),
		nestedLists = require("./modules/nestedList.js");


	// ==============================
	// Img protection
	// ==============================
	$('img').on("mousedown", function (e) {
		if (e.button == 2) {
			console.log("Right click");
			return false;
		}
	});

	// ==============================
	// Existence checker
	// ==============================
	$.fn.exists = function(){
		return this.length > 0;
	};

	// ==============================
	// Init standalone modules
	// ==============================
	if($(".screens").hasClass("bb-noscroll")){
		screenEvents.init(".bb-change", false);
	} else {
		screenEvents.init(".bb-change", true);
	}
	

	videoLoader.initVideos();

	if($(".js-auto-slider").exists()){
		autoSliders.initSliders(".js-auto-slider");
	}

	if($(".js-simple-slider").exists()){
		simpleSliders.init(".js-simple-slider");
	}

	// ==============================
	// Add animation delay to lists
	// ==============================
	function addListDelay(list_selector, items_selector){
		var $lists = $(list_selector);

		$lists.each(function(){
			var elements = $(this).find(">"+items_selector);

			elements.each(function(index){
				$(this).css({
					"animation-delay" : 0.2+index*0.1+"s"
				});
			});
		});
	}

	function checkSublists(list_selector, item_selector, sublist_selector){
		var $lists = $(list_selector);

		$lists.each(function(){
			var elements = $(this).find(item_selector);

			elements.each(function(index){
				var $list_item = $(this),
					$sublist = $list_item.find(sublist_selector);

				if($sublist){
					$list_item.addClass("has-sublist");
					$list_item.on("click", function(){
						$sublist.toggleClass("opened");
						$list_item.siblings().find(sublist_selector).removeClass("opened");
					});
				}
			});

		});
	}



	// ==============================
	// Prepare elements
	// ==============================
	
	// Init leave page animation
	pageFader.fadePageOn("a.preload-link, .main-menu__items>li:not(.no-fade)>a, .bb-pagination a", "#preloader", 500);
	
	// Prepare animations
	$(".bb-animation, .bb-animate-once").css({opacity: 0});

	// $(window).resize(function() {
	// 	var scrollbar = window.innerWidth - document.documentElement.clientWidth,
	// 		mobileBreakpoint = 768 - scrollbar;

	// 	console.log("Scroolbar: ", scrollbar, " / Breakpoint: ", mobileBreakpoint, " Window: ", $(window).width());
	// 	if($(window).width() > mobileBreakpoint){
	// 		$(".bb-animation, .bb-animate-once").css({opacity: 0});
	// 	} else {
	// 		$(".bb-animation, .bb-animate-once").css({opacity: 1});
	// 	}
	// });

	// Prepare links list animation
	if($(".links-list").exists()){
		addListDelay(".links-list", ".links-list__item");
		checkSublists(".links-list", ".links-list__item", ".links-list__sublist");
	}

	// Prepare products list animation
	if($(".products-list").exists()){
		addListDelay(".products-list", ".products-list__item");
	}

	// Add scrollbars
	if($(".bb-scroll").exists()){
		$(".bb-scroll").mCustomScrollbar({
			theme : "rounded-dots-dark",
			axis : "y",
			scrollInertia: 700,
			mouseWheel: {
				scrollAmount: 200
			}

		});
	}

	// Add event listener to nav-helpers
	if($(".bb-next").exists()){
		$(".bb-next").on("click", function(){
			screenEvents.showScreen("next", true);
		});
	}



	// ==============================
	// After load events
	// ==============================
	function activatePageLink(){
		var hash_link = window.location.hash,
			hash_reg = /^(#[a-z0-9-]+)$/gi;

		if($("#screen-navigation").exists() && hash_link.match(hash_reg)){
			var $needed_link = $("#screen-navigation").find("li[data-url="+hash_link+"]");

			if($needed_link){
				$needed_link.trigger("click");
			}
		}

	}


	function pageInit(){
		activatePageLink();

		$(".bb-animate-once").map(function(){
			var element = $(this);
			element.addClass(element.data("animation"));
		});

		$(".bb-change.active").loadScreen();
	}



	// ==============================
	// Products gallery module
	// ==============================
	if($("#products-gallery").exists()){
		productsGallery.init();
	}



	// ==============================
	// Google map init
	// ==============================
	if($("#bb-map").exists()){
		var map_selector = "#bb-map",
			icon_template = "#bb-marker-icon";

		map.init(map_selector,icon_template);
	}



	// ==============================
	// FAQ module
	// ==============================
	(function(){
		if($(".faq").exists()){
			var controls = "#faq-controls .links-list__item",
				items = "#faq-items .faq__answer";

			tabs(controls, items);

			if($("#faq-counter").exists()){
				var answers_counter = screenEvents.counter(items, "#faq-answers-current", "#faq-answers-total");

				$(controls).on("click", function(){
					var clicked = $(this),
						number = clicked.index()+1;

						answers_counter.update(number);
				});
			}
		}
	})();



	// ==============================
	// Contacts module
	// ==============================
	(function(){
		if($(".contacts").exists()){
			var controls = "#contacts-controls .contacts__city",
				items = "#contacts-items .address-panel";

			tabs(controls, items);
		}
	})();



	// ==============================
	// Content slider module
	// ==============================
	(function(){
		if($(".bb-slider").exists()){
			var sliders = $(".bb-slider");

			sliders.each(function(){
				var slider = $(this);

				bbslider.init(slider);
			});
		}
	})();


	// ==============================
	// Content gallery module
	// ==============================
	(function(){
		if($(".bb-mansongallery").exists()){
			var galleries = $(".bb-mansongallery");

			galleries.each(function(){
				var gallery = $(this);

				bbgallery.init(gallery);
			});
		}
	})();



	// ==============================
	// Main menu tab
	// ==============================
	$("#menu-toggle").click(function(){
		$(this).add("#main-menu, .header")
			.toggleClass("active");
	});



	// ==============================
	// Consultation tab
	// ==============================
	$(".consultation-toggle").click(function(){
		$("#consultation").toggleClass("active");
	});



	// ==============================
	// Buy tab
	// ==============================
	$(".buy-toggle").click(function(){
		$("#buy-form").toggleClass("active");
	});


	// ==============================
	// Nested Lists (accordeon like)
	// ==============================
	if($(".nested-list").exists()){
		nestedLists(".nested-list");
	}

	// ==============================
	// Mobile scrolltop
	// ==============================
	if($(".scroll-to-top").exists()){
		var $toTopButton = $(".scroll-to-top");

		$toTopButton.on("click", function(){
			$("html, body").stop().animate({scrollTop:0}, 700, "swing");
		})
	}

	// ==============================
	// Preloader
	// ==============================
	if($("#preloader").exists()){
		pageInit();
		$("#preloader").fadeOut(200);
		// $("#preloader").hide();

		// Wait all
		// preloader().done(function(){
			// pageInit();
		// });
	} else {
		pageInit();
	}

})(jQuery);
},{"./modules/autoSlider.js":2,"./modules/bbgallery.js":3,"./modules/bbslider.js":4,"./modules/gmap.js":5,"./modules/nestedList.js":6,"./modules/page_fader.js":7,"./modules/products-gallery.js":8,"./modules/screens.js":9,"./modules/simpleSlider.js":10,"./modules/tabs.js":11,"./modules/video_loader.js":12}],2:[function(require,module,exports){
function findSliders(selector){
	var $sliders = $(selector);

	$sliders.each(function(i, slider){
		setupSlider(slider);
	})
}

function setupSlider(slider){
	var timer,
		$slider = $(slider);

	$slider.find("> div:gt(0)").hide();
	
	$slider
		.mouseenter(function() {
			if (timer) { clearInterval(timer) }
		})
		.mouseleave(function() {
			timer = setInterval(function() {
				$slider.find("> div:first")
					.fadeOut(1000)
					.next()
					.fadeIn(1000)
					.end()
					.appendTo($slider);
			}, 3000);
		})
		// trigger mouseleave for initial slideshow starting
		.mouseleave();
}

module.exports = {
	initSliders : findSliders
};
},{}],3:[function(require,module,exports){
function init($gallery){
	var $images = $gallery.find(".bb-gallery__image"),
		$showscreen = $gallery.find(".bb-showscreen"),
		$showscreen_img = $showscreen.find(".bb-showscreen__img"),
		$showscreen_next = $showscreen.find(".bb-showscreen__next"),
		$showscreen_prev = $showscreen.find(".bb-showscreen__prev"),
		$showscreen_close = $showscreen.find(".bb-showscreen__close"),
		$showscreen_meta = $showscreen.find(".bb-showscreen__meta"),
		$showscreen_full = $showscreen.find(".bb-showscreen__full-link"),
		images_count = $images.length,
		index = 0;

	$showscreen_img.on("load", function(){
		$showscreen_img.fadeIn(200);
	});

	$images.on("click", function(){
		var $clicked = $(this),
			img_src = $clicked.data("src"),
			img_full = $clicked.data("full"),
			img_description = $clicked.data("descr");

		index = $images.index($clicked);
		$showscreen_img.attr("src", img_src);
		$showscreen_full.attr("href", img_full);
		$showscreen_meta.text(img_description);
		$showscreen.addClass("active");
	});

	$showscreen_close.on("click", function(){
		$showscreen.removeClass("active");
	});

	function showSlide(i){
		var $image = $images.eq(i),
			img_src = $image.data("src"),
			img_full = $image.data("full"),
			img_description = $image.data("descr");

		$showscreen_img.fadeOut(200, function(){
			$showscreen_img.attr("src", img_src);
			$showscreen_full.attr("href", img_full);
			$showscreen_meta.text(img_description);
		});
	}

	$showscreen_next.on("click", function(){
		index++;
		if(index>=images_count){
			index = 0;
		}
		showSlide(index);
	});

	$showscreen_prev.on("click", function(){
		index--;
		if (index<0) {
			index = images_count-1;
		}
		showSlide(index);
	});
}


module.exports = {
	init : init
};
},{}],4:[function(require,module,exports){
function init(slider_obj){
	var next_button = slider_obj.find(".bb-slider__next"),
		prev_button = slider_obj.find(".bb-slider__prev"),
		slides = slider_obj.find(".bb-slider__slides .slide"),
		active_slide = slides.filter('.active'),
		slides_count = slides.length,
		index = slides.index(active_slide);


	function showSlide(i){
		slides.eq(i)
			.addClass("active")
			.siblings()
			.removeClass("active");
	}

	next_button.on("click", function(){
		index++;
		if(index>=slides_count){
			index = 0;
		}
		showSlide(index);
	});

	prev_button.on("click", function(){
		index--;
		if (index<0) {
			index = slides_count-1;
		}
		showSlide(index);
	});
}


module.exports = {
	init : init
};
},{}],5:[function(require,module,exports){
var map_styles = [
	{
		"featureType": "water",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#d3d3d3" }
		]
	},{
		"featureType": "transit",
		"stylers": [
		{ "color": "#808080" },
		{ "visibility": "off" }
		]
	},{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#b3b3b3" }
		]
	},{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#ffffff" }
		]
	},{
		"featureType": "road.local",
		"elementType": "geometry.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#ffffff" },
		{ "weight": 1.8 }
		]
	},{
		"featureType": "road.local",
		"elementType": "geometry.stroke",
		"stylers": [
		{ "color": "#d7d7d7" }
		]
	},{
		"featureType": "poi",
		"elementType": "geometry.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#ebebeb" }
		]
	},{
		"featureType": "administrative",
		"elementType": "geometry",
		"stylers": [
		{ "color": "#a7a7a7" }
		]
	},{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#ffffff" }
		]
	},{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#ffffff" }
		]
	},{
		"featureType": "landscape",
		"elementType": "geometry.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#efefef" }
		]
	},{
		"featureType": "road",
		"elementType": "labels.text.fill",
		"stylers": [
		{ "color": "#696969" }
		]
	},{
		"featureType": "administrative",
		"elementType": "labels.text.fill",
		"stylers": [
		{ "visibility": "on" },
		{ "color": "#737373" }
		]
	},{
		"featureType": "poi",
		"elementType": "labels.icon",
		"stylers": [
		{ "visibility": "off" }
		]
	},{
		"featureType": "poi",
		"elementType": "labels",
		"stylers": [
		{ "visibility": "off" }
		]
	},{
		"featureType": "road.arterial",
		"elementType": "geometry.stroke",
		"stylers": [
		{ "color": "#d6d6d6" }
		]
	},{
		"featureType": "road",
		"elementType": "labels.icon",
		"stylers": [
		{ "visibility": "off" }
		]
	},{
	},{
		"featureType": "poi",
		"elementType": "geometry.fill",
		"stylers": [
		{ "color": "#dadada" }
		]
	}
	];

var init = function (map_selector, icon_selector){
	/*
	*  new_map
	*  This function will render a Google Map onto the selected jQuery element
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/

	function new_map( $el ) {
		var $markers = $el.find('.marker'),
			args = {
				zoom		: 10,
				center		: new google.maps.LatLng(0, 0),
				mapTypeId	: google.maps.MapTypeId.ROADMAP,
				disableDoubleClickZoom: false,
				mapTypeControl: false,
				streetViewControl: false,
				draggable : true,
				overviewMapControl: false,
				overviewMapControlOptions: {
					opened: false,
				},
				styles: map_styles
			};

		// create map
		var map = new google.maps.Map( $el[0], args);

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each(function(){
			add_marker( $(this), map );
		});

		// center map
		center_map( map );

		// return
		return map;
	}

	/*
	*  add_marker
	*  This function will add a marker to the selected Google Map
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function add_marker( $marker, map ) {
		// create marker
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
			marker = new google.maps.Marker({
				icon: marker_icon,
				position: latlng,
				map: map
			});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() ) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content	: $marker.html(),
				maxWidth : 200
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );

				// Track last opened window
				if (opened_infoWindow === null) {
					opened_infoWindow = infowindow;
				} else {
					opened_infoWindow.close();

					if (opened_infoWindow == infowindow) {
						opened_infoWindow = null;
					} else {
						opened_infoWindow = infowindow;
					}
				}

			});
		}

	}

	/*
	*  center_map
	*  This function will center the map, showing all markers attached to this map
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function center_map( map ) {
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ){
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );
		});

		// only 1 marker?
		if( map.markers.length == 1 ) {
			// set center of map
			map.setCenter( bounds.getCenter() );
			map.setZoom( 10 );
		} else {
			// fit to bounds
			map.fitBounds( bounds );
		}

	}

	/*
	*  document ready
	*  This function will render each map when the document is ready (page has loaded)
	*  @type	function
	*  @date	8/11/2013
	*  @since	5.0.0
	*  @param	n/a
	*  @return	n/a
	*/
	// global var
	var map = null,
		opened_infoWindow = null,
		marker_icon = $(icon_selector).data("icon");

	$(map_selector).each(function(){
		// create map
		map = new_map( $(this) );
	});
};


module.exports ={
	init : init
};
},{}],6:[function(require,module,exports){
module.exports = function(selector){
	var $lists = $(selector),
		$listsItemNames = $(selector + "__item-name", $lists);


	$listsItemNames
		.on("click", function(){
			$(this).parent().toggleClass("opened");
		});


};
},{}],7:[function(require,module,exports){
// ========================================================
// Show owerlay layer when specified link has been clicked
// ========================================================
function fadePageOn (link_selector, overlay_selector, time){
	$(document).on("click", link_selector, function(e) {
		var href = $(this).attr("href");
		if(href[0]!="#"){
			e.preventDefault();

			return $(overlay_selector)
				.addClass("loading")
				.fadeIn(time, function(){
					return document.location = href != null ? href : "/";
				});
		}
	});
}

module.exports = {
	fadePageOn : fadePageOn
};
},{}],8:[function(require,module,exports){
function init(){
	var animation_time = 600,
		gallery = $("#products-gallery"),
		controls_block = $("#products-gallery__controls"),
		controls = controls_block.find("li"),
		items_block = $("#products-gallery__items"),
		items = items_block.find("li"),
		cursor_area = $("#cursor-area"),
		cursorPlus = $("#cursor-plus");

	cursor_area.attr("href", controls.filter(".active").data("link"));

	// CHANGE ITEMS
	controls.on("click", function(){
		var clicked = $(this),
			index = clicked.index(),
			item = items.eq(index);

		clicked.add(item)
			.addClass("active")
			.siblings()
			.removeClass("active");

		cursor_area.attr("href", clicked.data("link"));
	});


	// CURSOR TRACKING
	cursor_area.mousemove(function(e) {
		cursorPlus.offset({
			left: e.pageX-60,
			top: e.pageY-60
		});
	});

	cursor_area.mouseleave(function(e) {
		cursorPlus.css({
			left: 30+"%",
			top: 30+"%"
		});
	});

	// SHOW PLUS PIC ON GALLERY ITEM HOVER
	cursor_area.hover(function(e){
			cursorPlus.addClass("active");
		}, function(e){
			cursorPlus.removeClass("active");
		});

}

module.exports = {
	init : init
};
},{}],9:[function(require,module,exports){
var $backPanel = $("#bg-panel"),
	screen_selector,
	$screens,
	$pagination = $("#screen-pager"),
	screen_counter,
	$screen_navigation,
	is_animated = false;


// =================================
// Scrollbar width
// =================================
function getScrollbarWidth() {
	return window.innerWidth - document.documentElement.clientWidth;
}


// =================================
// Counter
// =================================
function counter(countedElements_selector, current_selector, total_selector, remove_delay = 700){
	var current = $(current_selector),
		total = $(total_selector),
		elements = $(countedElements_selector);

	current.html("<span>1</span>");
	total.html(elements.length);

	var update = function(number){
		var new_number = $("<span class='bb-animation fade-in'>"+ number +"</span>"),
			current_number = current.find("span");

		if ( number != parseInt(current_number.text()) ) {
			current_number
				.removeClass("fade-in")
				.addClass("bb-animation fade-out removing")
				.css({
					"position" : "absolute"
				});

			new_number.appendTo(current);

			setTimeout(function(){
				current.find(".removing").remove();
			}, remove_delay);
		}
	};

	return {
		current : current,
		total : total,
		update : update
	};
}


// =================================
// Process screen animations
// =================================
var processScreen = function (state, animation_class) {
	var screen = $(this),
		selector = animation_class || ".bb-animation",
		animated_elements = screen.find(selector),
		panel_state = screen.data("bg-state");

	animated_elements.map(function(){
		var element = $(this),
			animation_in = element.data("animation-in"),
			animation_out = element.data("animation-out");

		if(state === "load"){
			element.addClass(animation_in)
				.removeClass(animation_out);
		} else {
			element.addClass(animation_out)
				.removeClass(animation_in);
		}
	});

	
	if(panel_state){
		if(panel_state=="hideUp"){
			$backPanel.addClass(panel_state);
		} else {
			$backPanel.removeClass()
				.addClass(panel_state);
		}
	}

	return this;
};


// =================================
// Process screen shorthands
// =================================
var loadScreen = function(){
	$(this).processScreen("load");
	return this;
};

var leaveScreen = function(){
	$(this).processScreen("leave");
	return this;
};

// Shorthands
$.fn.processScreen = processScreen;
$.fn.loadScreen = loadScreen;
$.fn.leaveScreen = leaveScreen;



// =================================
// Change screens on scroll
// =================================
function showScreen(which_one, process_navigation, scroll_delay = 700){
	var screenToShow,
		show_index,
		activeScreen = $screens.filter(".active"),
		nextScreen = activeScreen.next(screen_selector),
		prevScreen = activeScreen.prev(screen_selector);

	if(!is_animated){
		is_animated = true;

		setTimeout(function(){
			is_animated = false;
		}, scroll_delay);

		if(which_one === "next"){
			screenToShow = nextScreen.exists() ? nextScreen : $screens.eq(0);
		} else if (which_one === "prev") {
			screenToShow = prevScreen.exists() ? prevScreen : $screens.eq(-1);
		} else {
			screenToShow = $screens.eq(which_one);
		}

		activeScreen.leaveScreen().removeClass("active");
		screenToShow.addClass("active").loadScreen();

		show_index = $screens.index(screenToShow) + 1;

		if(screen_counter){
			screen_counter.update(show_index);
		}

		if(process_navigation === true){
			$screen_navigation.eq(show_index-1)
				.addClass("active")
				.siblings()
				.removeClass("active");
		}
	}

}


// =================================
// Initialization
// =================================
function init(selector = ".bb-change", scrollable) {
	var navigation_existence = false,
		$screens_wrapper = $(".screens"),
		scrollBarWidth = getScrollbarWidth(),
		mobileBreakpoint = 768 - scrollBarWidth;

	// Set app wide single screen class and select all screens to change
	screen_selector = selector;
	$screens = $(screen_selector);
	
	// Check screen links navigation
	if($("#screen-navigation").exists()){
		navigation_existence = true;
		$screen_navigation = $("#screen-navigation").find("li");

		$screen_navigation.on("click", function(){
			var item_index = $(this).index(),
				item_url = $(this).data("url");

			showScreen(item_index, true);

			if(item_url){
				window.location.hash = item_url;
			}
		});
	}


	if($screens.length > 1){
		screen_counter = counter($screens, "#current-page", "#total-pages");

		if(scrollable){
			// $(window).resize(function() {
			// 	if($(window).width() > mobileBreakpoint){

					$screens_wrapper.on('mousewheel', function(event) {
						if(!$(event.target).parents(".mCSB_container").exists()){
		
							if (event.deltaY < 0){
								showScreen("next", navigation_existence);
							} else {
								showScreen("prev", navigation_existence);
							}
						}
		
					});
		
					$(document).swipe({
						fallbackToMouseEvents: false,
						excludedElements: ".feedback__input, .contacts-map",
						swipeRight: function(event, distance, duration, fingerCount, fingerData, currentDirection){
							showScreen("prev", navigation_existence);
						},
						swipeDown: function(event, distance, duration, fingerCount, fingerData, currentDirection){
							showScreen("prev", navigation_existence);
						},
						swipeLeft: function(event, distance, duration, fingerCount, fingerData, currentDirection){
							showScreen("next", navigation_existence);
						},
						swipeUp: function(event, distance, duration, fingerCount, fingerData, currentDirection){
							showScreen("next", navigation_existence);
						},
					});

				// } else {
				// 	$screens_wrapper.off();
				// 	$(document).off();
				// }
			// });
			

		}

	} else {
		if($pagination.exists()) {
			$pagination.remove();
		}
	}
}

module.exports = {
	init : init,
	showScreen : showScreen,
	counter : counter
};
},{}],10:[function(require,module,exports){
function init(selector){
	let $sliders = $(selector);

	$sliders.each(function(i, slider){
		setupSlider(slider, selector);
	})
}

function setupSlider(slider, selector){
	let $slider = $(slider),
		$next = $(selector + "--next"),
		$prev = $(selector + "--prev"),
		$slides = $(selector + "--slide", $slider),
		activeClass = "active",
		index = 0,
		slidesCount = $slides.length;

	$slides.eq(index).addClass(activeClass);

	function showSlide(i){
		$slides.eq(i)
			.addClass("active")
			.siblings()
			.removeClass("active");
	}

	$next.on("click", function(){
		index++;
		if(index >= slidesCount){
			index = 0;
		}
		showSlide(index);
	});

	$prev.on("click", function(){
		index--;
		if (index < 0) {
			index = slidesCount-1;
		}
		showSlide(index);
	});
}

module.exports = {
	init : init
}
},{}],11:[function(require,module,exports){
// ==============================
// Simple tabs
// ==============================

module.exports = function(control_selector, content_selector){
	var $controls = $(control_selector),
		$content = $(content_selector);

	$controls.on("click", function(event){
		event.stopPropagation();

		var $clicked = $(this),
			index = $controls.index($clicked);

		if(!$clicked.hasClass("active")){
			$controls
				.add($content)
				.removeClass("active");

			$content.eq(index)
				.add($clicked)
				.addClass("active");
		}
	});
};
},{}],12:[function(require,module,exports){
function findVideos() {
	let videos = document.querySelectorAll('.video');

	for (let i = 0; i < videos.length; i++) {
		setupVideo(videos[i]);
	}
}

function setupVideo(video) {
	let link = video.querySelector('.video__link');
	// let media = video.querySelector('.video__media');
	let iframeContainer = video.querySelector('.video__iframe');
	let button = video.querySelector('.video__button');
	let id = parseMediaURL(link);

	video.addEventListener('click', () => {
		let iframe = createIframe(id);

		link.remove();
		// button.remove();
		// video.appendChild(iframe);
		iframeContainer.appendChild(iframe);
	});

	link.removeAttribute('href');
	video.classList.add('video--enabled');
}

function parseMediaURL(media) {
	// let regexp = /https:\/\/i\.ytimg\.com\/vi\/([a-zA-Z0-9_-]+)\/maxresdefault\.jpg/i;
	let regexp = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]+)/i;
	let url = media.href;
	let match = url.match(regexp);

	return match[1];
}

function createIframe(id) {
	let iframe = document.createElement('iframe');

	iframe.setAttribute('allowfullscreen', '');
	iframe.setAttribute('allow', 'autoplay');
	iframe.setAttribute('src', generateURL(id));
	iframe.classList.add('video__media');

	return iframe;
}

function generateURL(id) {
	let query = '?rel=0&showinfo=0&autoplay=0&wmode=transparent';

	return 'https://www.youtube.com/embed/' + id + query;
}

module.exports = {
	initVideos : findVideos
};
},{}]},{},[1]);
